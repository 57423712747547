<template>
  <ion-page :key="isRender" v-show="!isB2cUser">
    <ion-header class="ion-no-border">
      <ion-toolbar no-border>
        <ion-grid>
          <ion-row v-if="previousSelectedCompanyId" class="align-center" id="row">
            <ion-col size="9" class="d-flex">
              <ion-buttons class="mr-2">
                <ion-back-button
                  text=""
                  color="primary"
                  mode="ios"
                  @click="goBack"
                  class="back-icon"
                  :default-href="prevPage"
                ></ion-back-button>
              </ion-buttons>
              <ion-label class="fs-3 fw-600 mt-label">{{
                currentPage === '/b2b/select-customer'
                  ? $t('selectCompany.select_company')
                  : currentPage === '/b2b/customers'
                  ? $t('manage_companies')
                  : ''
              }}</ion-label>
            </ion-col>
          </ion-row>
          <ion-row v-if="!previousSelectedCompanyId" class="ion-align-items-center">
            <ion-col class="title ion-text-start" size="10">
              <ion-title color="black" class="ion-text-start ion-no-padding pl-2">
                {{ $t('selectCompany.select_company') }}
              </ion-title>
            </ion-col>
            <ion-col size="2" class="ion-text-right">
              <ion-icon :icon="logOutOutline" @click="openPopover" />
            </ion-col>
          </ion-row>
          <div class="pl-1 pr-1 mt-1">
            <ion-grid>
              <ion-row class="ion-align-items-center">
                <ion-col
                  size="12"
                  class="search-company"
                  :class="isStartSearch ? 'new-search' : 'new-search-off'"
                >
                  <ion-searchbar
                    mode="ios"
                    ref="searchInput"
                    @ionChange="handleSearch"
                    @ionFocus="setSearch(true)"
                    @ionBlur="setSearch(false)"
                    show-clear-button="never"
                    :debounce="1200"
                    :disabled="disableSearch || isOpenLoadingref"
                    class="search"
                    :placeholder="$t('search_company')"
                    @keyup.enter="handleEnter"
                  >
                  </ion-searchbar>
                  <ion-icon
                    v-show="keySearch.length > 0"
                    class="close-modal"
                    :icon="closeCircle"
                    @click="clearSearch"
                  ></ion-icon>
                </ion-col>
              </ion-row>
            </ion-grid>
            <div class="add-pic-button" v-if="enablePicConnectByPin">
              <ion-button expand="block" @click="onAddPicPin(true)">
                {{ $t('connect_to_company') }}
              </ion-button>
            </div>
          </div>
        </ion-grid>
      </ion-toolbar>
    </ion-header>
    <ion-content :scroll-events="true" @ionScroll="handleScroll($event)" ref="customerList">
      <skeleton-select-customer v-if="isOpenLoadingref || isSearch" />
      <div v-else>
        <boarding-state
          v-if="isShowBoarding"
          @refreshAct="refreshAct"
          :currentStep="firstCompanyStatus === 'pending' && listConnectPerson?.length === 0 ? 2 : 1"
        />
        <!-- Company request -->
        <div v-if="listConnectPerson?.length > 0 && (!showZeroResult || list?.data?.length > 0)">
          <div class="header bg-orange3" color="grey7" @click="toggleCollapse('request')">
            <div>
              {{ $t('company_request') }}
              <span class="counter px-2" color="grey7">
                {{ listConnectPerson?.length ?? 0 }}
              </span>
              <div
                v-if="currentPage === '/b2b/select-customer'"
                style="font-size: 12px; margin-top: 5px; font-weight: lighter"
              >
                {{ $t('you_have_company') }}
              </div>
            </div>
            <div class="px-1 fs-4 ml-3" :class="['toggle-icon', listCollapse.request ? 'rotate-180' : '']">
              <ion-icon slot="icon-only" :icon="chevronDownOutline" color="black7" />
            </div>
          </div>
          <Transition name="slide-up">
            <div v-if="listCollapse.request">
              <div v-for="(item, idx) in listConnectPerson || []" :key="idx">
                <card-company
                  :id="'connect'"
                  :item="item"
                  :isPendingRequest="true"
                  @onAccept="
                    presentAlertRequest(
                      headerAccept,
                      messagesAccept + ' ' + item.position?.toLowerCase() + ' ' + 'for ' + item.customer_name,
                      true,
                      item.id,
                      item.customer_name,
                      item.supplier_name,
                      item.position?.toLowerCase()
                    )
                  "
                  @onReject="
                    presentAlertRequest(
                      headerReject,
                      messagesReject +
                        ' ' +
                        item.customer_name +
                        ' ' +
                        item.position?.toLowerCase() +
                        ' ' +
                        'person?',
                      false,
                      item.id,
                      item.customer_name,
                      item.supplier_name,
                      item.position?.toLowerCase()
                    )
                  "
                />
              </div>
            </div>
          </Transition>
        </div>
        <!-- Company in review -->
        <div
          v-if="
            list?.companiesInReviews?.length > 0 && (listConnectPerson?.length > 0 || list?.data?.length > 0)
          "
        >
          <div class="header bg-orange3" color="grey7" @click="toggleCollapse('inReview')">
            <div>
              {{ $t('company_in_review') }}
              <span class="counter" color="grey7">
                {{ list?.companiesInReviews?.length ?? 0 }}
              </span>
              <div style="font-size: 12px; margin-top: 5px; font-weight: lighter">
                {{ $t('we_are_still_process') }}
              </div>
            </div>
            <div class="px-1 fs-4 ml-3" :class="['toggle-icon', listCollapse.inReview ? 'rotate-180' : '']">
              <ion-icon slot="icon-only" :icon="chevronDownOutline" color="black7" />
            </div>
          </div>
          <Transition name="slide-up">
            <div v-if="listCollapse.inReview">
              <div v-for="item in list?.companiesInReviews || []" :key="item.id">
                <card-company :id="'review'" :item="item" :isPendingReview="true" />
              </div>
            </div>
          </Transition>
          <ion-text @click="refreshAct" class="ion-text-center f-w-500 center-align" color="primary">
            <ion-icon :icon="refresh_icon"></ion-icon>
            {{ $t('refresh') }}
          </ion-text>
        </div>

        <!-- all company -->
        <div v-if="list?.data?.length >= 1">
          <div class="header bg-gray7" color="grey7" @click="toggleCollapse('all')">
            <div>
              {{ $t('all_companies') }}
              <span class="counter" color="grey7">
                {{ list?.totalCount ?? 0 }}
              </span>
            </div>
            <div class="px-1 fs-4 ml-3" :class="['toggle-icon', listCollapse.all ? 'rotate-180' : '']">
              <ion-icon slot="icon-only" :icon="chevronDownOutline" color="black7" />
            </div>
          </div>
          <Transition name="slide-up">
            <div v-if="listCollapse.all">
              <div slot="content" v-for="item in list?.data || []" :key="item.id">
                <card-company
                  :ref="`cardCompanyRef-${item.id}`"
                  :activeTab="activeTab"
                  :currentPage="currentPage"
                  :item="item"
                  :customerSelect="selectedCompany"
                  :id="'showDetail'"
                  @onSelectCustomer="applyCustomer(item)"
                />
              </div>
            </div>
          </Transition>
        </div>
        <div v-if="list?.data?.length <= 0 && showZeroResult && !loading" class="company-not-found mx-4">
          <ion-img src="/assets/images/not-found.svg" class="not-found-img" />
          <div class="not-found-text mt-3 mb-2">{{ $t('search_company_not_found') }}</div>
          <div class="try-different">
            {{
              `${$t('sorry_could_not_find')} ${keySearch === '' ? refKeySearch : keySearch}. ${$t(
                'try_different_name'
              )}`
            }}
          </div>
        </div>
        <ion-infinite-scroll
          @ionInfinite="loadMore($event)"
          threshold="100px"
          id="infinite-scroll"
          :disabled="isDisabled"
        >
          <ion-infinite-scroll-content loading-spinner="bubbles" loading-text="Loading ...">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </div>
    </ion-content>
    <ion-footer v-if="isShowAddCompanyBtn || currentPage === '/b2b/customers'" class="form-group px-3 py-2">
      <div class="form-group-btn">
        <ion-button mode="md" color="primary" @click="addCompany" :disabled="disableAddCompanyButton"
          ><ion-icon :icon="addOutline" />{{ $t('customerPage.add_company') }}</ion-button
        >
      </div>
    </ion-footer>
    <ion-modal
      :is-open="isOpenRef"
      ref="modal"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      css-class="default-bottom-sheet-modal"
      @didDismiss="setOpenBranchSelection(false)"
    >
      <branch-selection
        @closeModal="closeModalBranchSelection"
        @resetPath="resetPath"
        :customerSelect="selectedCompany"
        @resetSearch="resetSearch"
      ></branch-selection>
    </ion-modal>
    <ion-modal
      :is-open="isOpenModalLogoutRef"
      css-class="modal-confirm custom-modal"
      @didDismiss="setOpenModalLogout(false)"
    >
      <modal-content-confirm
        :title="$t('sign_out')"
        :content="$t('are_you_sure')"
        @confirm-yes-action="logout"
        @confirm-no-action="setOpenModalLogout(false)"
      >
      </modal-content-confirm>
    </ion-modal>

    <ion-modal ref="modal" :is-open="picPinModalOpen">
      <modal-add-pic-pin @close-modal="onAddPicPin(false)" />
    </ion-modal>
  </ion-page>
</template>

<script>
import refresh_icon from '@/assets/icons/shared/refresh.svg';
import { apolloClient } from '@/main';
import { addConnectPerson, checkConnectPerson } from '@/modules/b2b/services/graphql';
import { getAddressFormatWithStall } from '@/modules/b2b/services/libs/helper';
import { MUTATIONS } from '@/modules/b2b/store/account/mutations';
import { ACTIONS as ACTIONS_ADDRESS } from '@/modules/b2b/store/address/actions';
import { ACTIONS } from '@/modules/b2b/store/customer/actions';
import { ACTIONS as ACTIONS_PAYMENT } from '@/modules/b2b/store/payment/actions';
import ModalContentConfirm from '@/modules/b2b/views/shared/ModalContentConfirm.vue';
import SkeletonSelectCustomer from '@/modules/shared/components/select-customer/SkeletonSelectCustomer.vue';
import { useLoading } from '@/modules/shared/utils/';
import { getPicConnectByPinConfig } from '@/services/shared/graphql';
import branch from '@/services/shared/helper/branch';
import PushNotification from '@/services/shared/helper/push-notification';
import { ACTIONS as ROOT_ACTIONS } from '@/store/actions';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { alertController, useBackButton } from '@ionic/vue';
import {
  addOutline,
  arrowBackOutline,
  chevronDownOutline,
  closeCircle,
  closeOutline,
  idCardOutline,
  logOutOutline
} from 'ionicons/icons';
import { defineComponent, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { createNamespacedHelpers, mapActions as mapActionsRoot } from 'vuex';
import BranchSelection from './BranchSelection.vue';
import BoardingState from './components/BoardingState.vue';
import CardCompany from './components/CardCompany.vue';
import ModalAddPicPin from './components/ModalAddPicPin.vue';
const { mapMutations: mapMutationsAccount } = createNamespacedHelpers('b2b/account');
const { mapActions: mapActionsPayment } = createNamespacedHelpers('b2b/payment');
const { mapActions, mapGetters } = createNamespacedHelpers('b2b/customer');
const { mapActions: mapActionsAddress } = createNamespacedHelpers('b2b/address');

export default defineComponent({
  name: 'select-customer',
  components: {
    BranchSelection,
    ModalContentConfirm,
    CardCompany,
    BoardingState,
    SkeletonSelectCustomer,
    ModalAddPicPin
  },
  setup() {
    const { locale, t } = useI18n();
    const isOpenRef = ref(false);
    const router = useRouter();
    const setOpenBranchSelection = (state) => (isOpenRef.value = state);
    const ionRouter = inject('navManager');
    const isOpenModalLogoutRef = ref(false);
    const setOpenModalLogout = (state) => (isOpenModalLogoutRef.value = state);
    const { showLoading } = useLoading();
    const addCompany = () => {
      router.push('/b2b/customers/add/no-companies');
    };
    useBackButton(10, async () => {
      const currentPage = window.history?.state?.current;
      const prevPage = window.history?.state?.back;
      const prevPath = prevPage.split('/').slice(1, 3).join('/');
      if (prevPage === '/b2b/customers/add/no-companies' || prevPath === 'shared/auth') {
        setOpenModalLogout(true);
      } else if (currentPage === '/b2b/customers/add/no-companies') {
        router.replace('/b2b/select-customer');
      } else {
        router.replace(prevPage);
      }
    });
    const headerAccept = t('addCompany.accept_company_request');
    const messagesAccept = t('addCompany.would_you_like');
    const headerAcceptSuccAss = t('addCompany.success_assigned');
    const headerReject = t('addCompany.reject_company_request');
    const messagesReject = t('addCompany.would_you_like_to_reject_being');
    const handleAcceptSuccRej = t('addCompany.success_rejected');

    return {
      isOpenRef,
      setOpenBranchSelection,
      showLoading,
      logOutOutline,
      arrowBackOutline,
      addOutline,
      closeCircle,
      closeOutline,
      idCardOutline,
      addCompany,
      router,
      isOpenModalLogoutRef,
      setOpenModalLogout,
      getAddressFormatWithStall,
      ionRouter,
      locale,
      Keyboard,
      chevronDownOutline,
      refresh_icon,
      firstCompanyStatus: ref(''),
      keySearch: ref(''),
      refKeySearch: ref(''),
      isDisabled: ref(false),
      customer: ref([]),
      previousSelectedCompanyId: ref(null),
      selectedCompany: ref(null),
      user: ref([]),
      listConnectPerson: ref([]),
      headerAccept,
      messagesAccept,
      headerAcceptSuccAss,
      headerReject,
      messagesReject,
      handleAcceptSuccRej,
      supplierName: ref(''),
      isRender: ref(1),
      listCollapse: ref({
        request: true,
        inReview: true,
        all: true
      }),
      showZeroResult: ref(false),
      isOpenLoadingref: ref(true),
      isSearch: ref(false),
      isStartSearch: ref(false),
      currentPage: ref(null),
      prevPage: ref(null),
      checkScrollPosition: ref(null),
      activeTab: ref('myCustomer'), // `myCustomer` & `untaggedCustomer`
      picPinModalOpen: ref(false),
      enablePicConnectByPin: ref(false)
    };
  },
  computed: {
    ...mapGetters(['list', 'totalCount', 'loading', 'params', 'customerTenants']),
    isShowBoarding() {
      return (
        (this.firstCompanyStatus === 'none' || this.firstCompanyStatus === 'pending') &&
        this.listConnectPerson?.length < 1 &&
        this.list?.data?.length < 1
      );
    },
    disableSearch() {
      return this.firstCompanyStatus === 'none' || this.firstCompanyStatus === 'pending';
    },
    disableAddCompanyButton() {
      return (
        (!this.isOpenLoadingref && !this.isShowBoarding && this.list?.companiesInReviews?.length > 0) ||
        (this.totalCount <= 0 &&
          !this.showZeroResult &&
          !this.isOpenLoadingref &&
          !this.isShowBoarding &&
          this.list?.companiesInReviews?.length > 0)
      );
    },
    isShowAddCompanyBtn() {
      return (
        !this.isOpenLoadingref &&
        !this.isShowBoarding &&
        this.list.data?.length <= 0 &&
        this.list.companiesInReviews?.length <= 0
      );
    },
    isB2cUser() {
      return !!this.user?.is_b2c;
    }
  },
  watch: {
    totalCount() {
      this.showNoCompanyFound();
      if (this.totalCount > 0) {
        this.isOpenLoadingref = false;
      }
      this.isDisabled = false;
    },
    keySearch() {
      this.keySearch !== '' ? (this.refKeySearch = this.keySearch) : '';
    },
    list() {
      this.isDisabled = false;
      if (this.list.data.length === this.totalCount) {
        this.isDisabled = true;
      }
    },
    isShowBoarding(value) {
      if (value) {
        this.addPicPrompt();
      }
    }
  },
  inject: ['$storage'],
  async mounted() {
    this.currentPage = this.$route.path;
    this.locale = await this.$storage.get('language');
    this.customer = await this.$storage.getSelectCustomer();
    let company = await this.$storage.getSelectedCompany();
    this.user = await this.$storage.getUser();
    this.selectedCompany = company;
    this.previousSelectedCompanyId = company ? company.id : null;
    this.prevPage = window.history?.state?.back;
    await this.getPicConnectByPinConfig();
  },
  async beforeRouteEnter(_, from) {
    if (from.path === '/b2b/add-company') {
      this.handleGetConnectPerson();
      this.keySearch = '';
      await this[ACTIONS.RELOAD_CUSTOMERS](this.keySearch);
    }
  },
  beforeRouteLeave() {
    if (this.list.data.length === 0 && this.firstCompanyStatus === 'pending') {
      window.onpopstate = () => {
        this.setOpenModalLogout(true);
      };
    }
  },
  ionViewDidLeave() {
    this.keySearch = '';
    this.$refs.searchInput.$el.value = '';
    this.prevPage = null;
    this.isOpenLoadingref = true;
  },
  async ionViewDidEnter() {
    this.isOpenLoadingref = true;
    this.user = await this.$storage.getUser();
    this.$refs.searchInput.$el.value = '';

    const content = this.$refs.customerList.$el;
    content.scrollToTop();

    const routeFirstCompany = ['/b2b/customers/add/no-companies', '/shared/auth/signup-info'].includes(
      window.history?.state?.back
    );
    if (routeFirstCompany) this.prevPage = '/b2b/main/home';
    else this.prevPage = window.history?.state?.back;
    this.currentPage = this.$route.path;

    const company = await this.$storage.getSelectedCompany();
    this.previousSelectedCompanyId = company ? company.id : null;

    if (this.route && this.route.redirectedFrom?.path.includes('b2b/customers')) {
      this[ACTIONS.RELOAD_CUSTOMERS]();
    }

    this.showLoading(async () => {
      await this.getData();
      await this.handleGetConnectPerson(); // check connect person when the page view is ready
      await this.autoSelectCompany(); // auto select company for b2c user
    });
  },
  methods: {
    ...mapActions([
      ACTIONS.GET_CUSTOMERS,
      ACTIONS.LOAD_MORE_CUSTOMERS,
      ACTIONS.GET_CUSTOMERS_BRANCH,
      ACTIONS.RELOAD_CUSTOMERS,
      ACTIONS.UPDATE_SELECTED_CUSTOMER,
      ACTIONS.UPDATE_QUERY_PARAMS,
      ACTIONS.GET_CUSTOMER_B2C,
      ACTIONS.GET_CUSTOMER_TENANTS
    ]),
    ...mapActionsPayment([ACTIONS_PAYMENT.GET_LIST_CUSTOMER_CARD]),
    ...mapMutationsAccount([MUTATIONS.RENDER_ACCOUNT]),
    ...mapActionsAddress([ACTIONS_ADDRESS.GET_ADDRESSES]),
    handleEnter() {
      if (Capacitor.isNativePlatform()) {
        this.Keyboard.hide();
      }
    },

    handleScroll(event) {
      this.checkScrollPosition = event?.detail?.scrollTop;
      if (event && Capacitor.isNativePlatform()) {
        this.Keyboard.hide();
      }
    },

    toggleCollapse(name) {
      this.listCollapse[name] = !this.listCollapse[name];
    },
    async handleSearch() {
      const currentSearchKey = this.$refs.searchInput.$el?.value;
      this.keySearch = currentSearchKey;
      this.isSearch = this.keySearch !== '' ? true : false;
      await this[ACTIONS.RELOAD_CUSTOMERS](this.keySearch);
      //time need only for show skeleton loading
      setTimeout(() => {
        this.isSearch = false;
        const content = this.$refs.customerList.$el;
        content.scrollToTop();
      }, 500);
    },
    ...mapActionsRoot([ROOT_ACTIONS.RESET_STATE]),
    async showNoCompanyFound() {
      this.showZeroResult =
        this.totalCount <= 0 && this.list?.__typename === 'ViewsUserCustomerBranches' ? true : false;
    },
    async getData() {
      this.isOpenLoadingref = true;
      await this[ACTIONS.UPDATE_QUERY_PARAMS]({
        ...this.params,
        searchQueries: this.keySearch,
        offset: 0,
        isLoadMore: false,
        tenant_id: this.user?.tenant?.id
      });
      await this[ACTIONS.GET_CUSTOMERS]({
        ...this.params,
        tenant_id: this.user?.tenant?.id
      });
      this.isOpenLoadingref = false;

      const list = JSON.parse(JSON.stringify(this.list));
      this.firstCompanyStatus = list.first_company_status;

      // set company interview list
      if (list.companiesInReviews?.length > 0) {
        this.$storage.setCompanyReviewList(list.companiesInReviews);
      } else {
        this.$storage.setCompanyReviewList([]);
      }
    },
    loadMore(e) {
      // check continue status
      if (this.list.data.length === this.totalCount) {
        this.isDisabled = true;
        return;
      }
      this[ACTIONS.LOAD_MORE_CUSTOMERS]({
        params: {
          isIncludeTotalCount: true,
          offset: this.list.data.length
        },
        onFinish: () => {
          e.target.complete();
        }
      });
    },
    async checkCompany() {
      let company = await this.$storage.getSelectedCompany();
      this.previousSelectedCompanyId = company ? company.id : null;
      this.selectedCompany = company;
    },
    async clearSearch() {
      this.resetSearch();
      await this[ACTIONS.RELOAD_CUSTOMERS](this.keySearch);
    },
    resetPath() {
      // give time to page closed first
      setTimeout(() => {
        this.currentPage = null;
      }, 1000);
    },
    resetSearch() {
      this.$refs.searchInput.$el.value = '';
      this.keySearch = '';
    },
    setSearch(params) {
      this.isStartSearch = params;
    },
    async applyCustomer(customer) {
      await this[ACTIONS.GET_CUSTOMERS_BRANCH]({
        ...this.params,
        tenant_id: this.user?.tenant?.id
      });
      await this[ACTIONS_PAYMENT.GET_LIST_CUSTOMER_CARD](customer.id);
      this.selectedCompany = customer;
      if (customer.branches && customer.branches.length > 0) {
        await this.$storage.setSelectedCompany(customer);
        await this.$storage.setSelectedCustomer(customer);
        this.setOpenBranchSelection(true);
      } else {
        this.setOpenBranchSelection(false);
        customer.branches = [];
        this[ACTIONS.UPDATE_SELECTED_CUSTOMER](customer);
        await this.$storage.setSelectedCompany(customer);
        await this.$storage.setSelectedCustomer(customer);
        await this[ACTIONS_ADDRESS.GET_ADDRESSES]({
          supplierId: customer.id,
          active: true
        });
        this.openHomePage(customer);
        this[MUTATIONS.RENDER_ACCOUNT](true);
        this.resetPath();
      }
    },
    async closeModalBranchSelection(hasSelected) {
      if (!hasSelected) {
        this.setOpenBranchSelection(false);
        return;
      }
      let company = await this.$storage.getSelectedCompany();
      await this[ACTIONS_ADDRESS.GET_ADDRESSES]({
        supplierId: company.id,
        active: true
      });
      this.setOpenBranchSelection(false);
      if (hasSelected) this.$router.push(this.prevPage);
    },
    async openPopover() {
      this.setOpenModalLogout(true);
    },
    async openHomePage(customer) {
      this.keySearch = '';
      if (
        ['/b2b/customers/add/no-companies', '/b2b/customers/add', '/b2b/add-company'].indexOf(this.prevPage) >
        -1
      ) {
        this.prevPage = '/b2b/main/home';
      }

      if (this.isB2cUser) {
        await this[ACTIONS.GET_CUSTOMER_TENANTS]({
          customerId: customer.id
        });
        if (this.customerTenants.length > 1) {
          // redirect to select stores page if customer has more than 1 tenant
          return await this.router.replace('/b2b/stores');
        } else if (this.customerTenants.length === 1) {
          // set tenant by selected customer instead of default tenant from user
          await this.$storage.setUserTenant(this.customerTenants[0]);
        }
      }

      await this.router.replace(this.prevPage ? this.prevPage : '/b2b/main/home');
    },
    async backHome() {
      await this.$storage.setBackChangeCompany(true);
      await this.ionRouter.navigate({
        routerLink: this.$route?.params?.path ? this.$route?.params?.path : this.prevPage
      });
      this.setOpenBranchSelection(true);
    },
    goBack() {
      if (this.currentPage === '/b2b/select-customer') {
        this.backHome();
        this.resetPath();
      } else {
        this.$router.push('/b2b/account');
      }
    },
    logout() {
      this.showLoading(async () => {
        const user = await this.$storage.getUser();
        return Promise.allSettled([
          // reset state vuex
          this[ROOT_ACTIONS.RESET_STATE](),
          // clear ionic storage
          this.$storage.clearStorage(),
          PushNotification.removeAllListenerAndSubscribe(user?.id),
          branch.logout()
        ])
          .then(() => {
            this.setOpenModalLogout(false);
          })
          .then(() => {
            this.$router.go('');
          });
      });
    },
    //update pic
    async handleGetConnectPerson() {
      if (!this.user?.id || this.user?.is_b2c) return;
      try {
        const { data } = await apolloClient.query({
          query: checkConnectPerson,
          variables: {
            userId: this.user.id
          }
        });
        this.listConnectPerson = data.checkConnectPerson;
      } catch (error) {
        this.presentAlert(error, 'alert');
      }
    },
    async handleRejectConnectPerson(params, customerName, pic) {
      try {
        await apolloClient.mutate({
          mutation: addConnectPerson,
          variables: {
            requestId: params,
            isAccept: false
          }
        });
        const message = `${
          this.supplierName +
          ' ' +
          this.$t('addCompany.have_been_notified') +
          ' ' +
          customerName +
          ' ' +
          pic +
          ' ' +
          'person.'
        }`;

        this.presentAlert(message, false);
      } catch (error) {
        this.presentAlert(error, 'alert');
      }
    },
    async handleAcceptConnectPerson(params, customerName, pic) {
      try {
        await apolloClient.mutate({
          mutation: addConnectPerson,
          variables: {
            requestId: params,
            isAccept: true
          }
        });
        const message = `${
          this.$t('addCompany.you_are_now') +
          ' ' +
          customerName +
          '’s' +
          ' ' +
          pic +
          ' ' +
          'person.' +
          ' ' +
          this.$t('addCompany.you_can_now_make_order') +
          ' ' +
          customerName +
          '.'
        }`;
        this.presentAlert(message, true);
      } catch (error) {
        this.presentAlert(error, 'alert');
      }
    },
    async presentAlertRequest(header, message, request, params, customerName, supplierName, pic) {
      this.supplierName = supplierName;
      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'my-custom-class',
        header: header,
        message: request ? message + '?' : message,
        backdropDismiss: false,
        buttons: [
          {
            text: this.$t('cancel'),
            role: 'dismiss',
            cssClass: 'secondary',
            id: 'cancel-button'
          },
          {
            text: request ? this.$t('yes_confirm') : this.$t('yes_reject'),
            id: 'confirm-button',
            handler: () => {
              request
                ? this.handleAcceptConnectPerson(params, customerName, pic)
                : this.handleRejectConnectPerson(params, customerName, pic);
            }
          }
        ]
      });
      await alert.present();
    },
    async presentAlert(message, request) {
      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'my-custom-class',
        header:
          request === true
            ? this.$t('addCompany.success_assigned')
            : request === false
            ? this.$t('addCompany.success_rejected')
            : 'Alert',
        message: message,
        backdropDismiss: false,
        buttons: [
          {
            text: this.$t('OK'),
            id: 'confirm-button',
            handler: async () => {
              await this.getData();
              await this.handleGetConnectPerson();
              this.isRender++;
            }
          }
        ]
      });
      await alert.present();
    },
    async refreshAct() {
      await this.getData();
      await this.handleGetConnectPerson();
    },
    async autoSelectCompany() {
      if (!this.isB2cUser) return;

      if (!this.list.data || this.list.data.length === 0) {
        this.logout();
      }

      try {
        const id = this.list.data[0].id;
        this.$refs[`cardCompanyRef-${id}`][0].onTapCustomerCard(id);
      } catch (error) {
        console.error(error);
        this.logout();
      }
    },
    onAddPicPin(value) {
      this.picPinModalOpen = value;
    },
    async addPicPrompt() {
      if (!this.enablePicConnectByPin) {
        return;
      }

      if (!this.isShowBoarding) {
        return;
      }

      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'my-custom-class',
        message: this.$t('onboarding_pin_input_prompt_message'),
        backdropDismiss: false,
        buttons: [
          {
            text: this.$t('skip'),
            role: 'dismiss'
          },
          {
            text: this.$t('confirm'),
            handler: () => {
              this.picPinModalOpen = true;
            }
          }
        ]
      });

      await alert.present();
    },
    async getPicConnectByPinConfig() {
      try {
        const res = await apolloClient.query({
          query: getPicConnectByPinConfig
        });
        if (res?.data) {
          this.enablePicConnectByPin = res.data.getPicConnectByPinConfig;
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
});
</script>

<style scoped lang="scss">
.title {
  font-size: 20px;
  line-height: 32px;
}
.search {
  padding-right: 0px;
  padding-left: 0px;
}

#row {
  height: 50px;
}
.pending-action {
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  color: #181818;
  background: #fdc40c0f;
  font-size: 17px;
  line-height: 20px;
}
.my-companies {
  display: flex;
  align-items: center;
  font-size: 17px;
  line-height: 20px;
  color: #181818;
  background: rgba(34, 36, 40, 0.06);
  width: 100%;
  height: 44px;
}
.pending-action ion-text:last-child {
  padding: 3px;
  background: #2224280f;
  border-radius: 20px;
}

ion-item {
  --inner-padding-end: 10px;
  --inner-padding-start: 10px;
}
.form-companies {
  display: flex;
  margin-top: 23px;
  margin-bottom: 4px;
}
.form-avatar {
  position: relative;
  width: 40px;
  margin-left: 5px;
  margin-right: 20px;
}
ion-avatar {
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
  width: 36px;
  height: 36px;
}

.form-text ion-label {
  font-size: 16px;
  line-height: 20px;
  color: #181818;
}
.form-text ion-label h3 {
  font-weight: bold;
}
.form-text ion-text {
  margin-top: 4px;
  font-size: 16px;
  color: #667085;
  line-height: 22px;
}
ion-button {
  text-transform: none;
}

.form-btn {
  display: flex;
  justify-content: flex-end;
}
.form-btn .btn {
  --border-radius: 10px;
}
.counter {
  border-radius: 100px;
  padding: 3px 5px;
  font-size: 14px;
  background-color: #f04438;
  color: #fff;
}
.toggle-icon {
  transition: transform 0.3s ease;
  position: absolute;
  right: 10px;
  top: 10px;
}
.rotate-180 {
  transform: rotate(180deg);
}
.header {
  padding: 20px 30px;
  font-weight: bold;
  position: sticky;
  width: 100%;
}
.company-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  margin-top: 22vh;
}
.not-found-img {
  height: 120px;
  width: 120px;
}
.not-found-text {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.7px;
}
.try-different {
  text-align: center;
}
.add-new-btn {
  margin-top: 10px;
  height: 44px;
  font-size: 16px;
  font-weight: 500;
  --border-radius: 10px;
  & > ion-icon {
    font-weight: 500;
    margin-right: 2px;
  }
}

.bg-orange3 {
  background: #fdf5ed;
}
.bg-gray7 {
  background: #eeeeee;
}
ion-icon {
  font-size: 28px;
  color: rgb(195, 0, 0);
}
ion-button {
  text-transform: none;
}
ion-searchbar {
  --background: transparent;
  --icon-color: #00676a;
  border: 3px solid transparent;
  margin-top: -3px;
  font-size: 15px;
}
.new-search {
  border: 1.3px solid #00676a;
  border-radius: 12px;
  height: 45px;
  background: #ffffff;
  color: black;
}
.new-search-off {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  height: 45px;
  background: #f5f5f5;
  color: #9e9e9e;
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateY(30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.f-w-500 {
  font-weight: 500;
}
.center-align {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.hidden {
  opacity: 0;
}
.avatar {
  --border-radius: 9999px;
}

.align-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.align-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
}
.back-icon {
  margin-top: -6px;
}
.form-group {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  background-color: #ffffff;
  &-btn {
    width: 100%;
    & > ion-button {
      margin-top: 10px;
      width: 100%;
      height: 44px;
      font-size: 16px;
      font-weight: 500;
      --border-radius: 10px;
      & > ion-icon {
        font-weight: 500;
        margin-right: 2px;
        color: white;
      }
    }
  }
}
.search-company {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-content: center;
  justify-content: space-between;
}
.close-modal {
  margin-top: 3px;
  margin-right: 4px;
  font-size: 28px;
  color: #9e9e9e;
}
.mt-label {
  margin-top: 2px;
}
ion-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
}
</style>
